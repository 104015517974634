// Packages
import React, { useState } from 'react';
import { graphql } from 'gatsby';

// Types
import type { SiteMetadata, Location } from '~types/Gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';
// Comppnents
import Layout from '~components/layouts/Default';
import PageHeader from '~components/sections/PageHeader';
import Container from '~components/common/Container';
import H1 from '~components/typography/H1';
import H2 from '~components/typography/H2';
import P from '~components/typography/P';

import Grid from '~components/common/Grid';
import Image from '~components/common/Image';
import { CheckCircleIcon, ClockIcon, EmojiHappyIcon, QuestionMarkCircleIcon } from '@heroicons/react/outline';

import FaqItem from '~components/common/FaqItem';
import NewsletterForm from '~components/forms/newsletter/NewsletterForm';

import { customHeadingClasses } from '../../utils/helpers';
import ModalWithoutButton from '~components/common/ModalWithoutButton';
import Divider from '~components/sections/Divider';
import Button from '~components/common/Button';
import SvgAppStore from '~components/common/svgs/SvgAppStore';
import SvgPlayStore from '~components/common/svgs/SvgPlayStore';

interface DataProps {
  site: {
    siteMetadata: SiteMetadata;
  };
  imageHeader: IGatsbyImageData;
  klickMich: IGatsbyImageData;
  figure2: IGatsbyImageData;
  figure9: IGatsbyImageData;
  figure10: IGatsbyImageData;
  figure11: IGatsbyImageData;
  figure19: IGatsbyImageData;
  figure21: IGatsbyImageData;
  figure22: IGatsbyImageData;
  figure3: IGatsbyImageData;
  testmonial1: IGatsbyImageData;
  testmonial2: IGatsbyImageData;
  testmonial3: IGatsbyImageData;
}

export default function Page({ data, location }: { data: DataProps; location: Location }) {
  const [showModalUser1, setShowModalUser1] = useState(false);
  const [showModalUser2, setShowModalUser2] = useState(false);
  const [showModalUser3, setShowModalUser3] = useState(false);

  return (
    <Layout location={location} title="Entspannungshelden App - Entspannung, wann + wo du willst" desc="Entspannung aus deiner Hosentasche. Mehr Wohlbefinden per Smartphone" minimalFooter={true}>
      <PageHeader
        image={data.imageHeader}
        imageText="Bei Teilnahme an Präventionsangeboten, unterstützt deine gesetzliche Krankenkasse die Nutzung der App mit mindestens 75 Euro"
        classNameImage="max-w-md mx-auto animate-shake-vertical"
        divider
      >
        <H1 className={`${customHeadingClasses.huge} mb-5 font-bold`}>
          Entspannung,
          <br />
          wann + wo du willst
        </H1>
        <P className={`${customHeadingClasses.h4} mb-5`}>Entspannung aus deiner Hosentasche. Mehr Wohlbefinden per Smartphone</P>
        <div className="grid grid-cols-1 gap-4 md:flex">
          <a href="https://apps.apple.com/de/app/entspannungshelden-app/id6479883365" target="_blank" rel="noreferrer noopener">
            <SvgAppStore />
          </a>

          <a href="https://play.google.com/store/apps/details?id=com.entspannungshelden.app" target="_blank" rel="noreferrer noopener">
            <SvgPlayStore />
          </a>
        </div>
      </PageHeader>
      <Container>
        <Grid cols={2}>
          <div>
            <H2 className={`${customHeadingClasses.h2} mb-5`}>Was wir tun.</H2>
            <P>
              Über die Entspannungshelden App ermöglichen wir dir ein entspannteres Leben. Mit uns lernst du Anspannung und Stress zu reduzieren, einen gesunden Umgang mit dir und deinem Stress zu finden und deinen Alltag wirksam zu
              entspannen.
            </P>
          </div>
        </Grid>
      </Container>
      <Container>
        <div className="mx-auto max-w-2xl text-center">
          <H2 className={`${customHeadingClasses.h2} mb-5`}>Typische Nutzergruppen</H2>
        </div>
        <div className="mt-12">
          <Grid cols={3}>
            <UserGroups
              setShowModal={setShowModalUser1}
              showModal={showModalUser1}
              imageFigure={data.figure2}
              imageArrow={data.klickMich}
              cite="Ich bin total gestresst!"
              text="Leidest unter Verspannungen, Kopf- oder Rückenschmerzen, Zähneknirschen oder anderen Stressreaktionen? Vielleicht wirst du auch immer kraft- und energieloser? Das sind typische Anzeichen dafür, dass du dein Nervenkostüm ein Schweizer Käse ist und du dringend mehr Entspannung brauchst. Mit der Entspannungshelden App, hältst du die Lösung in deiner Hand."
            />
            <UserGroups
              setShowModal={setShowModalUser2}
              showModal={showModalUser2}
              imageFigure={data.figure9}
              cite="Wie kann ich entspannen?"
              text="Gerade Menschen wie du leiden oft unter ihren eigenen Ansprüchen. Perfektionismus, es allen Recht machen zu wollen oder auch der Drang alles selber zu erledigen, führen oft dazu, dass du dich selbst vergisst. Du hast verlernt zu Entspannen und Abzuschalten.  Mit der Entspannungshelden App findest du unterschiedliche Zugänge zur Entspannung und findest deinen Weg in den Feierabend."
            />
            <UserGroups
              setShowModal={setShowModalUser3}
              showModal={showModalUser3}
              imageFigure={data.figure10}
              cite="Ich möchte an mir arbeiten."
              text="Du hast bereits erkannt, dass Entspannung ein wesentlicher Bausteine für deine Gesundheit ist und erkennst, wenn du unter Stress zu leiden beginnst. Du hast es geschafft Pausen in deinen Alltag zu integrieren und mit dem Abschalten klappt es immer besser. Doch du merkst, da sind noch Themen in dir. Die Entspannungshelden App liefert dir über kurze Wissenssnacks ein tieferes Verständnis für deine offene Themen."
            />
          </Grid>
        </div>
      </Container>

      <Container>
        <Grid cols={2} items="center">
          <div>
            <H2 className={`${customHeadingClasses.h2} mb-5`}>Warum wir es tun.</H2>
            <P className="mb-3">Wir möchten Entspannung gesellschaftsfähig machen, ohne dir mit möglichen Stressfolgen und Burnout Angst zu machen. </P>
            <P className="mb-3">Wir glauben fest daran, dass du Entspannung erlernen und trainieren wirst, wenn es dir Spaß macht und du merkst, wie gut es dir tut. </P>
            <P className="mb-3">Wir sind der Überzeugung, dass du es durch die Entspannungshelden App schaffst, deine Stressoren zu identifizieren, deine Stressverstärker zu hinterfragen und Entspannung zu erleben. </P>
            <P>Dafür gehen wir an den Start!</P>
          </div>
          <div>
            <Image imageData={data.figure11} alt={''} className="mx-0" />
          </div>
        </Grid>
      </Container>

      <Container>
        <Grid cols={2} items="center">
          <div className="text-center">
            <VerticalTimeline
              lines={[
                {
                  text: 'Analysieren deiner Wünsche und Ziele.',
                  icon: QuestionMarkCircleIcon
                },
                {
                  text: 'Angebot passender Entspannungsverfahren.',
                  icon: CheckCircleIcon
                },
                {
                  text: 'Du wählst die optimale Länge für dich aus.',
                  icon: ClockIcon
                },
                {
                  text: (
                    <>
                      <span className="font-bold">Wohlbefinden</span> durch <span className="font-bold">Entspannung</span> steigern.
                    </>
                  ),
                  icon: EmojiHappyIcon
                }
              ]}
            />
          </div>
          <div>
            <H2 className={`${customHeadingClasses.h2} mb-5`}>Wie wir es tun.</H2>
            <P>
              Wir lernen dich kennen und stimmen die Inhalte der App auf deine Stimmung und Bedürfnisse ab. Du wählst aus unseren Vorschlägen aus, wie du entspannen möchtest und entscheidest über die Art und Dauer der Entspannung. Oder
              entscheidest dich für einen unserer Wissenssnack, die dich deiner Selbst wieder ein Stückchen näher bringen.
            </P>
          </div>
        </Grid>

        <div className="my-16 text-center lg:my-32">
          <H2 className={`${customHeadingClasses.h1} mx-auto mb-5 max-w-3xl italic`}>&bdquo;Das einzige Mittel, Zeit zu haben, ist, sich Zeit zu nehmen.&ldquo;</H2>
          <p>Bertha Eckstein</p>
        </div>
      </Container>

      <Container>
        <div className="mx-auto max-w-2xl text-center">
          <H2 className={`${customHeadingClasses.h2} mb-5`}>Ergebnisse unserer Kunden</H2>
        </div>
        <Grid cols={3} className="my-12">
          {[
            {
              text: 'Mehr Energie für schöne Dinge!',
              cite: 'Durch die kurzen Auszeiten, habe ich deutlich mehr Energie in meinem Alltag.',
              author: {
                name: 'Verena Dislich',
                handle: 'Inhaberin einer Physiotherapiepraxis',
                image: data.testmonial2
              }
            },
            {
              text: 'Abwechslungsreiche Übungsauswahl',
              cite: 'Die unterschiedlichen Übungen sind wirklich abwechslungsreich. Da ist für jeden was dabei!',
              author: {
                name: 'Nina S.',
                handle: 'Studierende der Sozialen Arbeit M.A,',
                image: data.testmonial3
              }
            },
            {
              text: 'Entspannung als fester Termin',
              cite: 'Mit den Helden entspanne ich 7 Minuten täglich. Mehr brauchts für mich nicht.',
              author: {
                name: 'Torben P.',
                handle: 'Lehrer an einer Grundschule',
                image: data.testmonial1
              }
            }
          ].map((testimonial) => (
            <React.Fragment key={testimonial.author.name}>
              <figure className="relative">
                <Image imageData={testimonial.author.image} alt={''} className="mx-0" />
                <div className="my-4 text-center">
                  <P className="relative font-display text-4xl font-bold leading-tight">{testimonial.text}</P>
                </div>
                <div className="text-center">
                  <P className="mb-4 text-xl italic">&bdquo;{testimonial.cite}&ldquo;</P>
                  <div className="text-lg font-semibold text-gray-900">{testimonial.author.name}</div>
                  <div className="mt-0.5 text-gray-600">{testimonial.author.handle}</div>
                </div>
              </figure>
            </React.Fragment>
          ))}
        </Grid>
      </Container>

      <div className="overflow-hidden bg-brand-green/50">
        <Divider className="text-gray-50" />
        <Container>
          <Grid cols={2} items="center">
            <div>
              <H2 className={`${customHeadingClasses.h2} mb-5`}>Da gibts noch was.</H2>
              <P className="mb-8">
                Entspannung ist ein Baustein der Gesundheit. Das haben auch die Krankenkassen erkannt. Daher kannst du durch die Teilnahme an den Ausgewählten und <strong>zertifizierten Präventionskursen</strong> richtig Geld sparen. Denn
                schon während der Kurse erhältst du den vollen Zugang zur App und erhältst nach Abschluss <strong>mindestens 75 Euro</strong> von deiner gesetzlichen Krankenversicherung zurück.{' '}
              </P>
              <Button to="/kurse/krankenkasse/" text="Zuschusshöhe prüfen" type="white" />
            </div>
            <div>
              <Image imageData={data.figure3} alt={''} className="mx-0" />
            </div>
          </Grid>
        </Container>
        <Divider className="rotate-180 text-gray-50" />
      </div>

      <Container>
        <Grid cols={2} items="center">
          <div>
            <H2 className={`${customHeadingClasses.h2} mb-5`}>
              Wer wir sind.
              <br />
              Einfach. Wirksam. Ehrlich.
            </H2>

            <P className="mb-3">
              Wir lieben die Einfachheit und Methoden die wirklich funktionieren. Doch das Internet ist voll von Angeboten und schnellen Lösungen, die dir in kürzester große Veränderung und das erreichen deiner Ziele versprechen.
            </P>

            <P className="mb-3">Doch so einfach ist das nicht. Im, Gegenteil, wäre es einfach ein neues Verhalten in den Alltag zu integrieren, wären wir alle schlank, sexy und glücklich. Leider braucht Veränderung Zeit.</P>

            <P className="mb-3">
              Wir legen daher den Fokus auf Methoden, die mit etwas Disziplin zu beständigen und langfristigen Veränderungen führen. Diesen Weg, möchten wir gerne mit dir gehen, egal ob er 6 Wochen, 9 Monate oder 2 Jahre dauert.
            </P>

            <P>
              Unsere App ist daher einfach gestaltet und leicht zu bedienen. Wir stehen vor der Herausforderung Inhalte zu entwickeln, die dich dabei unterstützen dein Ziel zu erreichen. Dafür nutzen wir dein Feedback und unsere
              Kompetenzen.{' '}
            </P>
          </div>
        </Grid>
      </Container>

      <div className="overflow-hidden bg-brand-green/50">
        <Divider className="text-gray-50" />
        <Container>
          <Grid cols={2} items="center">
            <div>
              <H2 className={`${customHeadingClasses.h2} mb-5`}>Von Beginn an dabei.</H2>
              <P className="mb-3">Sorry, noch sind wir nicht ganz fertig. Doch wir sitzen in unserer Garage und arbeiten auf Hochtouren an der Programmierung und Contenterstellung.</P>
              <P>Schon jetzt kannst du dich für die BETA Version der App eintragen und bist von Anfang an ein Teil vom Prozess. Wir suchen 200 aktive Nutzer:innen, die die App vorab testen und uns konstruktives Feedback geben.</P>
              <div className="mt-10 sm:mt-12">
                <NewsletterForm listId={10} dataProtecionText="Informiere Dich zur App." />
              </div>
            </div>
          </Grid>
        </Container>
        <Divider className="rotate-180 text-gray-50" />
      </div>

      <Container>
        <Grid cols={2}>
          <div>
            <H2 className="mb-4 text-brand-gray">
              Deine Fragen.
              <br />
              Unsere Antworten.
            </H2>
            <dl className="space-y-3">
              {[
                {
                  frage: 'Ab wann kann ich die App nutzen?',
                  text: 'Wir planen die Veröffentlichung in den Appstores gegen Ende 2023. Im Vorfeld testen wir eine Beta Version mit circa 200 Nutzern um das Feedback in die Entwicklung einfließen zu lassen.'
                },
                {
                  frage: 'Was wird die App kosten?',
                  text: 'Es wird eine werbegestützte kostenfreie Variante geben, die funktionsfähig ist, bestimmte Inhalte jedoch einschränkt. Um alle Inhalte uneingeschränkt nutzen zu können, investierst du 12,99 Euro pro Monat., was einem Drehspießteller entspricht.'
                },
                {
                  frage: 'Warum sollte ich gerade eure App nutzen?',
                  text: 'Weil wir die App anhand deiner Bedürfnisse und Feedback programmieren. Das heißt, dass du aktiv mitgestaltest, über die Implementierung oder Ändern von Funktionen mitentscheidest. Denn dein Erfolg ist unser Ziel!'
                },
                {
                  frage: 'Wie kann ich den Krankenkassenbeitrag erhalten?',
                  text: 'Aktuell arbeiten wir an einer Integration der Präventionskurse "Autogenes Training Onlinekurs" und "Progressive Muskelentspannung Onlinekurs", für die du schon jetzt einen Zuschuss in Höhe von 75.00 - 169.00 Euro erhältst. Nutzer der Kurse erhalten einen 1-Jahres Gratiszugang zur Vollversion der App.'
                }
              ].map((faq) => {
                return <FaqItem title={faq.frage} text={<p>{faq.text}</p>} key={faq.frage} />;
              })}
            </dl>
          </div>
        </Grid>
      </Container>
    </Layout>
  );
}

interface UserGroupsProps {
  setShowModal: (value: boolean) => void;
  showModal: boolean;
  imageFigure: IGatsbyImageData;
  imageArrow?: IGatsbyImageData;
  cite: string;
  text: string;
}

export const UserGroups: React.FC<UserGroupsProps> = ({ setShowModal, showModal, imageFigure, imageArrow, cite, text }) => {
  return (
    <>
      <div className="group relative cursor-pointer" onClick={() => setShowModal(true)}>
        <div className="absolute bottom-0 left-0 right-0 top-16 rounded-2xl bg-brand-green object-cover transition-colors duration-300 hover:bg-sky-700 group-hover:bg-brand-green-dark" />
        <Image imageData={imageFigure} alt={cite} className="mx-0" />
        {imageArrow && (
          <div className="absolute left-0 top-0">
            <Image imageData={imageArrow} alt="" />
          </div>
        )}
        <div className="flex items-center justify-center" style={{ minHeight: '7rem' }}>
          <P className="relative my-4 text-center text-2xl italic text-white">&bdquo;{cite}&ldquo;</P>
        </div>
      </div>
      <ModalWithoutButton open={showModal} setOpen={setShowModal} dialogTitle={cite}>
        <P>{text}</P>
      </ModalWithoutButton>
    </>
  );
};

interface FaqItemProps {
  lines: FaqItemLineProps[];
}

interface FaqItemLineProps {
  text: string | JSX.Element;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: JSX.Element | any;
}

export const VerticalTimeline: React.FC<FaqItemProps> = ({ lines }) => {
  return (
    <div className="inline-block rounded-xl bg-brand-green p-8 text-left">
      <ol className="relative border-l border-gray-200">
        {lines.map((line, index) => {
          const isLastItem = index === lines.length - 1;
          const IconElement = line.icon;
          return (
            <li className={`${isLastItem ? '' : 'mb-10'} ml-4`} key={index}>
              <div className="absolute -left-1.5 mt-1.5 h-3 w-3 rounded-full border border-white bg-gray-200" />
              <h3 className="text-lg font-semibold text-white">
                <IconElement className={`mr-3 inline-block h-8 w-8 text-white`} aria-hidden="true" /> {line.text}
              </h3>
            </li>
          );
        })}
      </ol>
    </div>
  );
};

interface UserResultsProps {
  imageFigure: IGatsbyImageData;
  text: string;
}

export const UserResults: React.FC<UserResultsProps> = ({ imageFigure, text }) => {
  return (
    <>
      <div className="relative">
        <div className="absolute bottom-0 left-0 right-0 top-16 rounded-2xl bg-brand-green object-cover" />
        <Image imageData={imageFigure} alt={text} className="mx-0" />
        <div className="flex items-center justify-center" style={{ minHeight: '9rem' }}>
          <P className="relative my-4 text-center font-display text-4xl font-bold leading-tight">{text}</P>
        </div>
      </div>
    </>
  );
};

export const query = graphql`
  {
    imageHeader: file(relativePath: { eq: "pages/app/app_figures_4.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    klickMich: file(relativePath: { eq: "pages/app/klick-mich.png" }) {
      childImageSharp {
        gatsbyImageData(width: 80)
      }
    }
    figure2: file(relativePath: { eq: "pages/app/app_figures_2.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    figure9: file(relativePath: { eq: "pages/app/app_figures_9.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    figure10: file(relativePath: { eq: "pages/app/app_figures_10.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    figure11: file(relativePath: { eq: "pages/app/app_figures_11.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    figure19: file(relativePath: { eq: "pages/app/app_figures_19.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    figure22: file(relativePath: { eq: "pages/app/app_figures_22.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    figure21: file(relativePath: { eq: "pages/app/app_figures_21.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    figure3: file(relativePath: { eq: "pages/app/app_figures_3.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    testmonial1: file(relativePath: { eq: "pages/app/testmonial-1.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    testmonial2: file(relativePath: { eq: "pages/app/testmonial-2.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    testmonial3: file(relativePath: { eq: "pages/app/testmonial-3.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
